



































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import helpers from "@/utils/helpers";
import DetailTag from "@/components/UIComponents/DetailTag.vue";
import { JWTModule } from "@/store/modules";

@Component({
  components: { DetailTag },
})
export default class Profile extends Vue {
  get AdminRouter() {
    return AdminRouter;
  }
  get userProfile() {
    return JWTModule.DetailProfile;
  }
  get userType() {
    return this.userProfile.profile.userType;
  }
  get approvalStatus() {
    if (this.userProfile && this.userProfile.profile) {
      return this.userProfile.profile.approvalStatus;
    }
    return "";
  }

  get id() {
    const profile = helpers.getUserProfile();
    if (!profile) return false;
    return profile.id;
  }

  get approvedUser() {
    const profile = helpers.getUserProfile();
    if (profile && profile.userDetail && profile.userDetail.profile) {
      return profile.userDetail.profile.approvalStatus == "Approved"
        ? true
        : false;
    }
    return false;
  }

  get approvalText() {
    const profile = helpers.getUserProfile();
    if (profile && profile.userDetail && profile.userDetail.profile) {
      let approvalStatus = profile.userDetail.profile.approvalStatus;
      if (approvalStatus == "Pending") {
        approvalStatus = "Approval on hold";
      }
      return approvalStatus;
    }
    return "";
  }

  reapply() {
    this.$confirm(
      "You cannot edit your profile after reapplying registration. Continue?",
      "Reapply for registration",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(async () => {
        await JWTModule.reapplyVehicleOwner(this.userProfile.id);
        this.$snotify.success("Reapplied for registration");
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Reapply canceled",
        });
      });
  }

  reapplyGps() {
    this.$confirm(
      "You cannot edit your profile after reapplying registration. Continue?",
      "Reapply for registration",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(async () => {
        await JWTModule.reapplyGps(this.userProfile.id);
        this.$snotify.success("Reapplied for registration");
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Reapply canceled",
        });
      });
  }

  hasRoles(roles: string[]) {
    return helpers.hasRoles(roles);
  }
  async created() {
    await JWTModule.getDetailProfile();
  }
}
